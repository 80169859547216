export enum ZawSubsidyReissueEligibility {
  RETURN_NEEDED = 'RETURN_NEEDED',
  SUBSIDY_WILL_BE_REISSUED = 'SUBSIDY_WILL_BE_REISSUED',
  SUBSIDY_EXPIRED = 'SUBSIDY_EXPIRED',
  ORDER_CONTAINS_MULTIPLE_ITEMS = 'ORDER_CONTAINS_MULTIPLE_ITEMS',
  GENERIC_WILL_NOT_BE_REISSUED = 'GENERIC_WILL_NOT_BE_REISSUED'
}

export const FORM_MESSAGES = {
  amazon: 'Amazon employee? Email Amazon@zappos.com',
  error: 'There was an error recording your response',
  success: 'Thank you for your response'
};

// ZAW API request attempts
export const ZAW_API_RETRY_COUNT = 3;

export const ZAW_PROGRAM_NAME = 'ZAW';
